<form
  [formGroup]="quoteForm"
  class="quote-form-interior"
  [ngClass]="{ 'one-page' : onePageLayout }"
  #formDirective="ngForm"
  (ngSubmit)="submitForm(formDirective)"
>
  <h2
    class="quote-form-interior__title"
    [ngClass]="mode ? mode + '-mode' : ''"
  >Start protecting your pet today!</h2>
  <span
    class="quote-form-interior__sub-title"
    [ngClass]="{ 'dark-mode': mode === 'dark' }"
  >Complete your free quote in seconds or call <a
      href="tel:(800) 779-1539"
      class="quote-form-interior__sub-title__underline"
    >(800) 779-1539</a></span>
	<div
		class="quote-form-interior__group"
		[ngClass]="{'one-page' : onePageLayout }"
	>
    <div
      [ngClass]="{ 'is--hidden': !onePageLayout && !isSectionOneVisible, 'one-page' : onePageLayout }"
      class="quote-form-interior__section m--section-one"
      formGroupName="formStepOne"
    >
      <div
        class="quote-form-interior__sub-section"
        [ngClass]="{ 'one-page' : onePageLayout }"
      >
        <div
          role="presentation"
          class="quote-form-interior__question m--pet-name"
        >
          <mat-form-field
            appearance="outline"
            hideRequiredMarker
          >
            <mat-label class="quote-form-interior__question-label">Pet's Name</mat-label>
            <input
              matInput
              formControlName="petName"
              type="text"
              required
              maxlength="100"
            />
            <ng-container
              *ngIf="petName?.touched && petName?.errors"
              [ngTemplateOutlet]="errorSvg"
              matSuffix
            ></ng-container>
            <mat-error *ngIf="petName?.errors?.['required']">Pet's Name is required</mat-error>
            <mat-error *ngIf="petName?.errors?.['pattern']">Pet's Name may not contain special characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="quote-form-interior__question m--pet-age">
          <mat-form-field
            appearance="outline"
            hideRequiredMarker
          >
            <mat-label class="quote-form-interior__question-label">Pet's Age</mat-label>
            <mat-select
              formControlName="age"
              placeholder="Select"
            >
              <mat-option [value]="999">0 - 6 weeks old</mat-option>
              <mat-option
                *ngFor="let age of ageListMock | keyvalue"
                [value]="age.key"
              > {{ age.value }} </mat-option>
            </mat-select>
            <ng-container
              *ngIf="age?.touched && age?.errors"
              [ngTemplateOutlet]="errorSvg"
              matSuffix
            ></ng-container>
            <mat-error *ngIf="age?.errors?.['required']">Pet's Age is required</mat-error>
            <mat-error *ngIf="age?.errors?.['max']">Pets need to be at least 6 weeks of age</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        role="presentation"
        class="quote-form-interior__row"
        [ngClass]="{ 'one-page' : onePageLayout }"
      >
        <div class="quote-form-interior__toggle m--species">
          <mat-button-toggle-group
            formControlName="species"
            aria-label="Pet's Species"
          >
            <mat-button-toggle value="dog">
              <ng-container
                class=""
                *ngIf="isDogSelected"
                [ngTemplateOutlet]="checkboxSvg"
              ></ng-container>
              <span class="quote-form-interior__toggle-text">Dog</span>
            </mat-button-toggle>
            <mat-button-toggle value="cat">
              <ng-container
                *ngIf="!isDogSelected"
                [ngTemplateOutlet]="checkboxSvg"
              ></ng-container>
              <span class="quote-form-interior__toggle-text">Cat</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="quote-form-interior__toggle m--sex">
          <mat-button-toggle-group
            formControlName="sex"
            aria-label="Pet's Sex"
            value="male"
          >
            <mat-button-toggle value="male">
              <ng-container
                *ngIf="isBoySelected"
                [ngTemplateOutlet]="checkboxSvg"
              ></ng-container>
              <span class="quote-form-interior__toggle-text">Male</span>
            </mat-button-toggle>
            <mat-button-toggle value="female">
              <ng-container
                *ngIf="!isBoySelected"
                [ngTemplateOutlet]="checkboxSvg"
              ></ng-container>
              <span class="quote-form-interior__toggle-text">Female</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <ng-container
        *ngIf="!onePageLayout"
        [ngTemplateOutlet]="petBreed"
      ></ng-container>
    </div>
    <button
      *ngIf="!onePageLayout"
      type="button"
      class="quote-form-interior__button mat-button"
      [ngClass]="{ 'is--hidden': !isSectionOneVisible, 'one-page' : onePageLayout }"
      [ngStyle]="{ 'background-color': color }"
      (click)="goToStepTwo()"
    > Next </button>
    <div
      [ngClass]="{ 'is--hidden': !onePageLayout && isSectionOneVisible, 'one-page' : onePageLayout }"
      class="quote-form-interior__section m--section-two"
      formGroupName="formStepTwo"
    >
      <div class="quote-form-interior__sub-section">
        <ng-container
          *ngIf="onePageLayout"
          [ngTemplateOutlet]="petBreed"
        ></ng-container>
        <div
          role="presentation"
          class="quote-form-interior__question m--email"
        >
          <mat-form-field
            appearance="outline"
            hideRequiredMarker
          >
            <mat-label class="quote-form-interior__question-label">Email</mat-label>
            <input
              matInput
              formControlName="emailAddress"
              type="email"
              inputmode="email"
            />
            <ng-container
              *ngIf="emailAddress?.touched && emailAddress?.errors"
              [ngTemplateOutlet]="errorSvg"
              matSuffix
            ></ng-container>
            <mat-error *ngIf="emailAddress?.errors?.['required']">Email is required</mat-error>
            <mat-error *ngIf="emailAddress?.errors?.['email']">Please enter a valid email address</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="quote-form-interior__sub-section">
        <div
          role="presentation"
          class="quote-form-interior__question m--zip-code"
        >
          <mat-form-field
            appearance="outline"
            hideRequiredMarker
          >
            <mat-label class="quote-form-interior__question-label">Zip Code</mat-label>
            <input
              matInput
              formControlName="zipCode"
              type="text"
              maxlength="5"
            />
            <ng-container
              *ngIf="zipCode?.touched && zipCode?.errors"
              [ngTemplateOutlet]="errorSvg"
              matSuffix
            ></ng-container>
            <mat-error *ngIf="zipCode?.errors?.['minlength'] && !zipCode?.errors?.['pattern']">Zip Code must be 5 digits
              long</mat-error>
            <mat-error *ngIf="zipCode?.errors?.['required']">Zip Code is required</mat-error>
            <mat-error *ngIf="zipCode?.errors?.['pattern']">Zip Code may only contain digits</mat-error>
          </mat-form-field>
        </div>
        <div
          role="presentation"
          class="quote-form-interior__question m--phone"
        >
          <mat-form-field appearance="outline">
            <mat-label class="quote-form-interior__question-label">Phone (Optional)</mat-label>
            <input
              qeInputMask="phone"
              matInput
              formControlName="phoneNumber"
              type="text"
              inputmode="tel"
            />
            <mat-error *ngIf="phoneNumber?.errors?.['minlength']">Phone Number must be 10 digits long
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div
    class="quote-form-interior__get-quote"
    [ngClass]="{ 'is--hidden': !onePageLayout && isSectionOneVisible }"
  >
    <p
      class="quote-form-interior__acknowledgement"
      [ngClass]="{ 'dark-mode': mode === 'dark' }"
    > By clicking ‘Get Quote’ you consent for us to contact you about Embrace Pet
      Insurance services via email, text, and phone calls to the contact information provided using automated
      technology. Phone number is not required to start quote. Your information is collected and used in
      accordance with Embrace’s <a
        class="quote-form-interior__acknowledgement-link"
        href="https://www.embracepetinsurance.com/privacy-policy"
        target="_blank"
      >Privacy Policy</a>. Opt out any time. </p>
    <div class="quote-form-interior__button-container">
      <button
        *ngIf="!isSectionOneVisible"
        [ngClass]="{ 'dark-mode': mode === 'dark' }"
        type="button"
        class="quote-form-interior__back-button"
        (click)="handleBackClick()"
      >
        <svg
          class="quote-form-interior__back-button-icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 1L1 12M1 12L12 23M1 12L23 12"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="quote-form-interior__back-button-text">Back</span>
      </button>
      <button
        [disabled]="isSubmitting"
        type="submit"
        class="quote-form-interior__button mat-button"
        [ngClass]="{ 'one-page' : onePageLayout }"
        [ngStyle]="{ 'background-color': color }"
        id="submitButton"
      > {{ !isSubmitting ? 'Get Quote' : 'Loading...' }} </button>
    </div>
    <p
      *ngIf="policyHolderExists"
      class="quote-form-interior__error"
    > This e-mail address is already registered with an existing policy. Visit MyEmbrace or call Customer Service to
      adjust your policy. </p>
    <p
      *ngIf="defaultError"
      class="quote-form-interior__error"
    >To complete your quote, please call <a href="tel:800-660-8726">800-660-8726</a>.</p>
    <ng-container>
      <p
        *ngFor="let error of ruleErrorList"
        class="quote-form-interior__error"
      > {{ error }} </p>
    </ng-container>
  </div>
</form>

<ng-template #checkboxSvg>
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="14.9369"
      cy="14.3345"
      rx="14.0014"
      ry="14"
      fill="currentColor"
      [ngStyle]="{ 'color': color }"
    />
    <path
      d="M22.5093 8.1025C23.104 7.47405 24.0502 8.39054 23.4555 9.01899C20.1573 12.6326 16.8322 16.1938 13.507 19.7812C13.3448 19.9645 13.1826 20.1216 13.0204 20.3049C12.7771 20.5668 12.3176 20.5406 12.0743 20.3049C10.3982 18.76 8.72208 17.1888 7.04599 15.6439C6.42421 15.0678 7.37039 14.1251 7.99217 14.7274C9.2585 15.8883 10.5059 17.0492 11.7661 18.2254C12.1748 18.6069 12.8158 18.584 13.196 18.1741C16.315 14.8114 19.4121 11.4683 22.5093 8.1025Z"
      fill="white"
    />
  </svg>
</ng-template>

<ng-template #errorSvg>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7999 13.5839L12.4083 2.98389C12.1498 2.57957 11.7937 2.24683 11.3728 2.01634C10.9519 1.78585 10.4798 1.66504 9.99992 1.66504C9.52005 1.66504 9.04791 1.78585 8.62702 2.01634C8.20613 2.24683 7.85004 2.57957 7.59159 2.98389L1.19992 13.5839C0.974132 13.9603 0.851411 14.3895 0.844097 14.8283C0.836783 15.2672 0.945133 15.7002 1.15825 16.0839C1.40465 16.5158 1.7613 16.8745 2.19176 17.1234C2.62221 17.3723 3.11103 17.5025 3.60825 17.5006H16.3916C16.8855 17.5058 17.3722 17.3807 17.8023 17.1378C18.2325 16.895 18.591 16.5429 18.8416 16.1172C19.061 15.7295 19.1728 15.2903 19.1655 14.8449C19.1581 14.3995 19.0319 13.9642 18.7999 13.5839ZM17.3833 15.2922C17.2831 15.4644 17.1378 15.6059 16.963 15.7013C16.7882 15.7968 16.5906 15.8427 16.3916 15.8339H3.60825C3.40927 15.8427 3.21168 15.7968 3.03689 15.7013C2.86209 15.6059 2.71674 15.4644 2.61659 15.2922C2.54345 15.1655 2.50494 15.0218 2.50494 14.8756C2.50494 14.7293 2.54345 14.5856 2.61659 14.4589L9.01659 3.85056C9.13159 3.69865 9.28023 3.57545 9.45084 3.49062C9.62144 3.40579 9.80939 3.36165 9.99992 3.36165C10.1905 3.36165 10.3784 3.40579 10.549 3.49062C10.7196 3.57545 10.8683 3.69865 10.9833 3.85056L17.3749 14.4506C17.4509 14.5775 17.4917 14.7223 17.4932 14.8703C17.4946 15.0182 17.4567 15.1638 17.3833 15.2922Z"
      fill="currentColor"
    />
    <path
      d="M9.99935 14.1672C10.4596 14.1672 10.8327 13.7941 10.8327 13.3338C10.8327 12.8736 10.4596 12.5005 9.99935 12.5005C9.53911 12.5005 9.16602 12.8736 9.16602 13.3338C9.16602 13.7941 9.53911 14.1672 9.99935 14.1672Z"
      fill="currentColor"
    />
    <path
      d="M9.99935 6.66748C9.77834 6.66748 9.56637 6.75528 9.41009 6.91156C9.25381 7.06784 9.16602 7.2798 9.16602 7.50081V10.8341C9.16602 11.0552 9.25381 11.2671 9.41009 11.4234C9.56637 11.5797 9.77834 11.6675 9.99935 11.6675C10.2204 11.6675 10.4323 11.5797 10.5886 11.4234C10.7449 11.2671 10.8327 11.0552 10.8327 10.8341V7.50081C10.8327 7.2798 10.7449 7.06784 10.5886 6.91156C10.4323 6.75528 10.2204 6.66748 9.99935 6.66748Z"
      fill="currentColor"
    />
  </svg>
</ng-template>

<ng-template #petBreed>
  <div class="quote-form-interior__question m--breed">
    <mat-form-field
      appearance="outline"
      hideRequiredMarker
    >
      <mat-label class="quote-form-interior__question-label">Pet's Breed</mat-label>
      <div class="input-container" style="position: relative;">
        <input
          [formControl]="breedDropdown"
          [matAutocomplete]="auto"
          matInput
          type="text"
          placeholder="Begin typing your pet’s breed"
          aria-label="Pet's Breed"
        />
        <button class="quote-form-interior__no-background-icon" *ngIf="!hideButton" mat-icon-button aria-label="Clear" (click)="clearBreedField()">
          <mat-icon class="quote-form-interior__no-background-icon">✖</mat-icon>
        </button>
      </div>
      <mat-autocomplete
        [displayWith]="displayFn"
        #auto="matAutocomplete"
        (optionSelected)="onOptionSelected()"
      >
        <mat-option
          *ngFor="let breed of filteredBreedOptions$ | async"
          [value]="breed"
          (mousedown)="onMouseDown($event)"
        > {{ breed.name }}
        </mat-option>
      </mat-autocomplete>
      <ng-container
        *ngIf="breedDropdown?.touched && breedDropdown?.errors"
        [ngTemplateOutlet]="errorSvg"
        matSuffix
      ></ng-container>
      <mat-error *ngIf="breedDropdown?.errors?.['required']">Pet's Breed is required</mat-error>
			<mat-error *ngIf="breedDropdown?.errors?.['requireMatch']">Pet's Breed is invalid</mat-error>
    </mat-form-field>
  </div>
</ng-template>
