/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
import {
	AfterViewInit,
	Directive,
	ElementRef,
	HostListener,
	Input,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { formatPhoneNumber } from "@utils/phone-number-formatter";

@Directive({
	selector: '[qeInputMask]',
})
export class InputMaskDirective implements AfterViewInit {
	@Input() qeInputMask!: string;
	private inputEl!: HTMLInputElement;
	private readonly acceptedButtons = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'];

	constructor (private readonly el: ElementRef, private readonly control: NgControl) { }

	@HostListener('keydown', ['$event'])
	_handleKeydown (ev: KeyboardEvent): void {
		const currentInputValue = this.inputEl?.value.replace(/\D/g, '') ?? '';
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		if (this.qeInputMask === 'phone' && currentInputValue.length === 10 && !this.acceptedButtons.includes(ev.code)) {
			ev.preventDefault();
			ev.stopImmediatePropagation();
		}
	}

	@HostListener('input')
	_handleInput (): void {
		setTimeout(() => {
			this.handleInput();
		}, 0);
	}

	@HostListener('change')
	_handleChange (): void {
		setTimeout(() => {
			this.handleInput();
		}, 0);
	}


	ngAfterViewInit (): void {
		this.inputEl = this.el.nativeElement;
		this.handleInput();
	}

	private handleInput (): void {
		switch (this.qeInputMask) {
			case 'phone':
				this.maskPhoneNumber();
				break;
			case 'expiry':
				this.maskExpiryDate();
				break;
			default:
				return;
		}
	}

	private maskPhoneNumber (): void {
		if(this.control && this.control.control){
			this.control.control.setValue(formatPhoneNumber(this.inputEl.value));
		} else {
			this.inputEl.value = formatPhoneNumber(this.inputEl.value);
		}
	}

	private maskExpiryDate (): void {
		const expiryRegEx = /(\d{2})(\d{2})/;
		this.el.nativeElement.value = this.inputEl.value
			.replace(/(\D)/g, '')
			.replace(expiryRegEx, '$1/$2');
	}

}
