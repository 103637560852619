import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { getEndpoint, RESOURCES } from '@constants/api-endpoints'
import { Breed } from '@models/Breed'
import { BehaviorSubject, Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class BreedService {
	#breedList: BehaviorSubject<Breed[]> = new BehaviorSubject(<Breed[]>[])
	public readonly breedList$: Observable<Breed[]> = this.#breedList.asObservable()
	filteredOptions: Observable<Breed[]> | undefined

	constructor(private readonly http: HttpClient) {}

	public getBreedsBySpecies(species: string) {
		return this.http.get<Breed[]>(getEndpoint(RESOURCES.BREED)).pipe(
			map(pets =>
				pets
					.filter(pet => pet.speciesName.toLowerCase() === species && [262,271,471].indexOf(pet.id) < 0)
					// eslint-disable-next-line @typescript-eslint/no-magic-numbers
					.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
			)
		)
	}
}
