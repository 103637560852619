import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import {
	FormControl,
	FormGroupDirective,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { TSFixme } from '@app/models/TSFixme';
import { Ages } from '@constants/ages';
import { API_ERRORS } from '@constants/api-errors';
import { petNameRegEx } from '@constants/validation-regex';
import { Breed } from '@models/Breed';
import { Quote } from '@models/Quote';
import { BreedService } from '@services/breed.service';
import { QuoteService } from '@services/quote.service';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { stripSpecialChars } from '@utils/phone-number-formatter';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
	selector: 'qf-quote-form',
	templateUrl: './quote-form.component.html',
	styleUrls: ['./quote-form.component.scss'],
})
export class QuoteFormComponent implements OnInit {
	@HostBinding('[data-emb-element]') embAttribute = true;
	@Input('mode') mode: string | undefined;
	@Input('color') color: string | undefined;
	@Input('data-brand') dataBrand: string | undefined;
	@Input('partnerSenderId') partnerSenderId: string | undefined;
	@Input('utm_source') utm_source: string | undefined;
	@Input('utm_medium') utm_medium: string | undefined;
	@Input('utm_campaign') utm_campaign: string | undefined;
	@Input('utm_content') utm_content: string | undefined;
	@Input('utm_term') utm_term: string | undefined;
	@Input('onePageLayout') set setOnePageLayout(onePageLayout: boolean | '') {
		this.onePageLayout = onePageLayout === '' || !!onePageLayout;
	}

	@ViewChild(FormGroupDirective)
	private formDir!: FormGroupDirective;

	@HostListener('document:visibilitychange', ['$event'])
	handleVisibilityChange(event: any): void {
		if (document.hidden) {
		} else {
			if (!this.quoteForm.touched) {
				this.breedsList$ = this.breedService.getBreedsBySpecies('dog');

				this.quoteForm = this.fb.group({
					formStepOne: this.fb.group({
						petName: ['', [Validators.required, Validators.pattern(petNameRegEx)]],
						// eslint-disable-next-line @typescript-eslint/no-magic-numbers
						age: ['', [Validators.required, Validators.max(21)]],
						species: ['dog'],
						sex: ['male'],
						breedDropdown: ['', { validators: [Validators.required, this.requireMatch.bind(this)] }],
					}),
					formStepTwo: this.fb.group({
						zipCode: [
							'',
							{
								validators: [Validators.required, Validators.minLength(this.zipLength), Validators.pattern(/^\d*$/im)],
							},
						],
						emailAddress: ['', [Validators.required, Validators.email]],
						phoneNumber: ['', [Validators.minLength(this.phoneLength)]],
					}),
				});

				this.filteredBreedOptions$ = this.breedDropdown.valueChanges.pipe(
					startWith(''),
					map(v => (typeof v === 'string' ? v : v?.name)), // eslint-disable-line  @typescript-eslint/no-unsafe-argument
					switchMap((value: string) => this._filter(value))
				);

				this.species?.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(value => {
					this.breedsList$ = this.breedService.getBreedsBySpecies(value);
					this.breedDropdown.reset();

					this.isDogSelected = !this.isDogSelected;
					this.setBreedsList();
				});

				this.sex?.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
					this.isBoySelected = !this.isBoySelected;
				});

				this.breedService
					.getBreedsBySpecies('dog')
					.pipe(takeUntil(this.ngUnsubscribe$))
					.subscribe(breeds => {
						this.breedsList = breeds;
					});

				this.isBoySelected = true;
				this.isDogSelected = true;
				this.hideButton = true;
			}
		}
	}

	public ageListMock: Map<number, string> = new Ages().values;
	public breedsList: Breed[] = [];
	public breedsList$: Observable<Breed[]>;
	public quoteForm: UntypedFormGroup;
	public isSectionOneVisible: boolean = true;
	public isSubmitting: boolean = false;
	public isDogSelected: boolean = true;
	public isBoySelected: boolean = true;
	public policyHolderExists: boolean = false;
	public ruleError: boolean = false;
	public ruleErrorList: string[] = [];
	public defaultError = false;
	public environment = environment;
	public onePageLayout = false;
	public hideButton = true;

	filteredBreedOptions$!: Observable<Breed[]>;

	private readonly zipLength: number = 5;
	private readonly phoneLength: number = 10;
	private readonly ngUnsubscribe$: Subject<boolean> = new Subject();

	constructor(
		private readonly fb: UntypedFormBuilder,
		private readonly breedService: BreedService,
		private readonly quoteService: QuoteService,
		private route: Router,
		private elRef: ElementRef
	) {
		this.breedsList$ = this.breedService.getBreedsBySpecies('dog');

		this.quoteForm = this.fb.group({
			formStepOne: this.fb.group({
				petName: ['', [Validators.required, Validators.pattern(petNameRegEx)]],
				// eslint-disable-next-line @typescript-eslint/no-magic-numbers
				age: ['', [Validators.required, Validators.max(21)]],
				species: ['dog'],
				sex: ['male'],
                breedDropdown: ['', { validators: [Validators.required, this.requireMatch.bind(this)] }],
			}),
			formStepTwo: this.fb.group({
				zipCode: [
					'',
					{
						validators: [Validators.required, Validators.minLength(this.zipLength), Validators.pattern(/^\d*$/im)],
					},
				],
				emailAddress: ['', [Validators.required, Validators.email]],
				phoneNumber: ['', [Validators.minLength(this.phoneLength)]],
			}),
		});
	}

	// Validators trigger on mouse down events, this prevents the validator showing when a user clicks on a breed
	// but still allows the auto complete to work
	onMouseDown(event: MouseEvent): void {
		event.preventDefault();
		const breedDropdownInputEl = this.elRef.nativeElement.querySelector('#breedDropdownInput');
		if (breedDropdownInputEl) {
			breedDropdownInputEl.focus();
		}
	}

	onOptionSelected() {
		this.quoteForm.get('formStepOne')?.get('breedDropdown')?.clearValidators();
		this.quoteForm.get('formStepOne')?.get('breedDropdown')?.disable();
		this.quoteForm.get('formStepOne')?.get('breedDropdown')?.markAsTouched();
		this.hideButton = false;
	}

	clearBreedField() {
		// We know we are explicitly clearing the breed field so don't need to check for touch
		this.quoteForm.get('formStepOne')?.get('breedDropdown')?.clearValidators();
		this.breedDropdown.setValue('');
		this.quoteForm
			.get('formStepOne')
			?.get('breedDropdown')
			?.addValidators([Validators.required, this.requireMatch.bind(this)]);
		this.quoteForm.get('formStepOne')?.get('breedDropdown')?.updateValueAndValidity();
		this.quoteForm.get('formStepOne')?.get('breedDropdown')?.markAsTouched();
		this.quoteForm.get('formStepOne')?.get('breedDropdown')?.enable();

		this.hideButton = true;

		if (this.quoteForm.get('formStepOne')?.get('age')?.untouched) {
			this.quoteForm.get('formStepOne')?.get('age')?.clearValidators();
			//this.quoteForm.get('formStepOne')?.get('age')?.updateValueAndValidity();
			this.quoteForm
				.get('formStepOne')
				?.get('age')
				?.addValidators([Validators.required, Validators.max(21)]);
		}

		if (this.quoteForm.get('formStepOne')?.get('petName')?.untouched) {
			this.quoteForm.get('formStepOne')?.get('petName')?.clearValidators();
			//this.quoteForm.get('formStepOne')?.get('petName')?.updateValueAndValidity();
			this.quoteForm
				.get('formStepOne')
				?.get('petName')
				?.addValidators([Validators.required, Validators.pattern(petNameRegEx)]);
		}

		if (this.quoteForm.get('formStepTwo')?.get('zipCode')?.untouched) {
			this.quoteForm.get('formStepTwo')?.get('zipCode')?.clearValidators();
			//this.quoteForm.get('formStepTwo')?.get('zipCode')?.updateValueAndValidity();
			this.quoteForm
				.get('formStepTwo')
				?.get('zipCode')
				?.addValidators([Validators.required, Validators.minLength(this.zipLength), Validators.pattern(/^\d*$/im)]);
		}

		if (this.quoteForm.get('formStepTwo')?.get('emailAddress')?.untouched) {
			this.quoteForm.get('formStepTwo')?.get('emailAddress')?.clearValidators();
			//this.quoteForm.get('formStepTwo')?.get('emailAddress')?.updateValueAndValidity();
			this.quoteForm.get('formStepTwo')?.get('emailAddress')?.addValidators([Validators.required, Validators.email]);
		}
	}
	public get petName() {
		return this.quoteForm.get(`formStepOne.petName`);
	}
	public get age() {
		return this.quoteForm.get(`formStepOne.age`);
	}
	public get species() {
		return this.quoteForm.get(`formStepOne.species`);
	}
	public get sex() {
		return this.quoteForm.get(`formStepOne.sex`);
	}
    public get breedDropdown() {
		return this.quoteForm.get(`formStepOne.breedDropdown`) as UntypedFormControl;
	}
	public get zipCode() {
		return this.quoteForm.get(`formStepTwo.zipCode`);
	}
	public get emailAddress() {
		return this.quoteForm.get(`formStepTwo.emailAddress`);
	}
	public get phoneNumber() {
		return this.quoteForm.get(`formStepTwo.phoneNumber`);
	}

	ngOnInit() {
		this.filteredBreedOptions$ = this.breedDropdown.valueChanges.pipe(
			startWith(''),
			map(v => (typeof v === 'string' ? v : v?.name)), // eslint-disable-line  @typescript-eslint/no-unsafe-argument
			switchMap((value: string) => this._filter(value))
		);

		this.species?.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(value => {
			this.breedsList$ = this.breedService.getBreedsBySpecies(value);
			this.breedDropdown.reset();

			this.isDogSelected = !this.isDogSelected;
		this.setBreedsList();
			this.hideButton = true;
			this.quoteForm
				.get('formStepOne')
				?.get('breedDropdown')
				?.addValidators([Validators.required, this.requireMatch.bind(this)]);
			this.quoteForm.get('formStepOne')?.get('breedDropdown')?.updateValueAndValidity();
			this.quoteForm.get('formStepOne')?.get('breedDropdown')?.markAsTouched();
			this.quoteForm.get('formStepOne')?.get('breedDropdown')?.enable();
		});

		this.sex?.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
			this.isBoySelected = !this.isBoySelected;
		});

		this.breedService
			.getBreedsBySpecies('dog')
			.pipe(takeUntil(this.ngUnsubscribe$))
			.subscribe(breeds => {
				this.breedsList = breeds;
			});
	}

	public goToStepTwo() {
		if (this.quoteForm.get('formStepOne')?.invalid) {
			this.quoteForm.get('formStepOne')?.markAllAsTouched();
		} else {
			this.isSectionOneVisible = false;
		}
	}

	public handleBackClick() {
		this.isSectionOneVisible = true;
	}

	private formatPhoneNumberForSubmit(phoneNumber: string): string {
		const strippedPhoneNumber = stripSpecialChars(phoneNumber);
		return strippedPhoneNumber.startsWith('1') ? strippedPhoneNumber.slice(1) : strippedPhoneNumber;
	}

	public submitForm(formDirective: FormGroupDirective) {
		this.quoteForm.get('formStepOne')?.markAllAsTouched();
        this.quoteForm.get('formStepTwo')?.markAllAsTouched();
		this.policyHolderExists &&= false;
		this.ruleError &&= false;
		this.defaultError &&= false;
		this.ruleErrorList.length = 0;

		if (this.quoteForm.valid) {
			this.isSubmitting = true;

			const phoneNumber = this.formatPhoneNumberForSubmit(this.phoneNumber?.value as string);
			const queryParams = this.route.parseUrl(window.location.search).queryParams;

            const utm_params = {
                ...queryParams,
                dataBrand: this.dataBrand || queryParams['brand'],
				partnerSenderId: this.partnerSenderId,
                utm_medium: this.utm_medium || queryParams['utm_medium'],
                utm_content: this.utm_content || queryParams['utm_content'],
                utm_source: this.utm_source || queryParams['utm_source'],
                utm_term: this.utm_term || queryParams['utm_term'],
                utm_campaign: this.utm_campaign || queryParams['utm_campaign'],
                channel: queryParams['utm_channel'],
			};

			let quoteData: Quote = {
				contact: {
					ratingZipCode: this.zipCode?.value,
					emailAddress: this.emailAddress?.value,
					phoneNumber: phoneNumber ?? '',
					brand: 'embrace',
				},
				pets: [
					{
						name: this.petName?.value,
						species: this.species?.value,
						gender: this.sex?.value,
						breedId: this.breedDropdown?.value.id,
						breedName: this.breedDropdown?.value.name,
						ageInYears: this.age?.value,
					},
				],
				analyticsInfo: {
					medium: utm_params.utm_medium,
					content: utm_params.utm_content,
					source: utm_params.utm_source,
					term: utm_params.utm_term,
					campaign: utm_params.utm_campaign,
					channel: utm_params.channel,
				},
				verificationInfo: {
					expectedAction: 'epi_widget'
				}
			};

			if (!window) return;
			const { kameleoon } = <Window & typeof globalThis & { kameleoon: TSFixme }>window;
			if (kameleoon) {
				const activeExperiments = kameleoon.API.Experiments.getActive() ?? '';
				//const quoteFormKameleoonId = 146730;
				//TODO: swap back to above experiment ID once tested in RC
				const quoteFormKameleoonId = 171285;
				if (activeExperiments) {
					for (const experiment of activeExperiments) {
						if (experiment.id === quoteFormKameleoonId) {
							quoteData = {
								...quoteData,
								experimentVariations: [
									{
										variationId: experiment.associatedVariation.id,
										variationSystemName: 'Kameleoon',
										variationAdded: new Date(),
									},
								],
							};
						}
					}
				}
			}

			this.quoteService
				.generateNewQuote(quoteData)
				.pipe(takeUntil(this.ngUnsubscribe$))
				.subscribe({
					complete: () => {
						var email = this.emailAddress?.value as string;
						var zipCode = this.zipCode?.value as string;
						this.isSubmitting = false;
						formDirective.resetForm();
						this.quoteForm.reset();
						this.quoteService.redirectToQuoteEngine(email, zipCode, utm_params);
					},
					error: (err: HttpErrorResponse) => {
						const title = err.error.title;

						this.isSubmitting = false;

						switch (title) {
							case API_ERRORS.QUOTE_EXECUTION_ERROR:
							case API_ERRORS.CONTACT_VALIDATION_MESSAGE:
							case API_ERRORS.PAYROLLDEDUCT_VALIDATION_MESSAGE:
								this.ruleError = true;
								this.ruleErrorList = [
									...this.ruleErrorList,
									...err.error.detail
										?.split(` :: `)[1]
										?.split(`;`)
										?.map((message: string) => message.trim()),
								];

								return;
							case API_ERRORS.POLICY_HOLDER_EXISTS:
								this.policyHolderExists = true;

								return;
							default:
                			this.defaultError = true;
								break;
						}
						console.error(err);
					},
				});
		}
	}

	public displayFn(option: Breed): string {
		return option && option.name ? option.name : '';
	}

	private _filter(value: string): Observable<Breed[]> {
		const filterValue = value ? value.toLowerCase() : '';

		return this.breedsList$.pipe(map(options => options.filter(option => option.name.toLowerCase().includes(filterValue))));
	}

	private async setBreedsList(): Promise<void> {
		this.breedsList = await firstValueFrom(this.breedsList$);
	}

	private requireMatch(control: FormControl): ValidationErrors | null {
		if (control.value) {
			const selection: any = control.value.id;
			const idList = this.breedsList.map(breed => breed.id);

			if (idList.indexOf(selection) < 0) {
				return { requireMatch: true };
			}
		}
		return null;
	}
}
