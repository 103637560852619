import { NgModule } from '@angular/core';

import { InputMaskDirective } from './input-mask.directive';

@NgModule({
	declarations: [
		InputMaskDirective,
	],
	exports: [
		InputMaskDirective
	]
})
export class SharedModule { }
