import { HttpClientModule } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, Injector, NgModule } from '@angular/core'
import { createCustomElement } from '@angular/elements'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { BrowserModule } from '@angular/platform-browser'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { QuoteFormComponent } from '@app/quote-form/quote-form.component'
import { SharedModule } from "@shared/shared.module";

const globalRippleConfig: RippleGlobalOptions = {
	disabled: true,
}

@NgModule({
	declarations: [QuoteFormComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MatInputModule,
		MatSelectModule,
		MatAutocompleteModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		HttpClientModule,
		SharedModule,
		RouterModule.forRoot([])
	],
	providers: [{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig }],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements DoBootstrap {
	constructor(private readonly injector: Injector) {
		const QuoteFormElementInterior = createCustomElement(QuoteFormComponent, { injector: this.injector })

		customElements.get('emb-quote-form-interior') || customElements.define('emb-quote-form-interior', QuoteFormElementInterior)
	}
	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
	ngDoBootstrap(): void {}
}
