import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { getEndpoint, RESOURCES } from '@constants/api-endpoints'
import { Quote } from '@models/Quote'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { environment as rc_environment } from 'src/environments/environment.rc'
import { rcHostName } from 'src/environments/AppEnvironment'

@Injectable({
	providedIn: 'root',
})
export class QuoteService {
    public env = environment;

	constructor(private readonly http: HttpClient) {
        if (window.location.hostname === rcHostName) {
            this.env = rc_environment;
        }
    }

	/**
	 * Used to start a new quote
	 *
	 * @param req The form data
	 */
	public generateNewQuote(req: Quote): Observable<Quote> {
		return this.http.post<Quote>(getEndpoint(RESOURCES.WIDGET), req)
	}

	public redirectToQuoteEngine(emailAddress: string, zipCode: string, utmData?: any): void {
		let utmParams = '';

        Object.keys(utmData).forEach(key => {
            if (utmData[key]) {
                utmParams += '&' + key + '=' + utmData[key];
            }
        })

		window.location.href = `${this.env.quoteEngineUrl}?email=${emailAddress}&zip=${zipCode}`
	}
}
