/**
 * Used for Pet Name validaion
 * allows digits, letters, spaces and some special characters
 * (period, underscore, hyphen, & apostrophe)
 */
export const petNameRegEx: RegExp = /^([a-z\d][\_\-\'\. a-z\d]*)$/im

/**
 * Used for First & last name validation
 * Allows letters, spaces, apostrophe, and hyphens
 */
export const petParentRegEx: RegExp = /^([a-z][\-\' a-z]+)$/im
