/* eslint-disable @typescript-eslint/no-magic-numbers */
/**
 *
 * @param rawPhoneNumber The raw phone number value.
 * Misformatted number will be reformatted into the correct format by stripping special chars before formatting
 */
export const formatPhoneNumber = (rawPhoneNumber: string = ''): string => {
	const strippedNumber = rawPhoneNumber.replace(/(\D)/g, '');

	const maskedValue = strippedNumber.startsWith('1')
		? strippedNumber.slice(1, 11).replace(/([\d]{3})([\d]{3})([\d]{4})/, '($1) $2-$3')
		: strippedNumber.slice(0, 10).replace(/([\d]{3})([\d]{3})([\d]{4})/, '($1) $2-$3');

	// eslint-disable-next-line @typescript-eslint/no-magic-numbers
	return maskedValue;
};

/**
 * Will strip special characters from the phone number
 *
 * @param formattedPhoneNumber The phone number that may contain special characters
 */
export const stripSpecialChars = (formattedPhoneNumber: string = ''): string => formattedPhoneNumber.replace(/\W/g, '');
