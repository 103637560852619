export class Ages {
	values: Map<number, string> = new Map()
	constructor() {
		/**
		 * Creates a map anywhere ages are needed
		 * To adjust list in all places the `ageLimit` number can be changed
		 */
		const ageLimit: number = 21
		;[...Array(ageLimit)].forEach((_, index) => {
			this.values.set(index, `${index < 1 ? '6 weeks - 12 months' : `${index} ${index > 1 ? 'years' : 'year'}`} old`)
		})
	}
}
