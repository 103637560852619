import { environment } from 'src/environments/environment'
import { environment as rc_environment } from 'src/environments/environment.rc'
import { rcHostName } from 'src/environments/AppEnvironment';

let env = environment;
if (window.location.hostname === rcHostName) {
    env = rc_environment;
}

const BASE_URL: string = `${env.apiEndpointOrigin}/${env.apiVersion}`

export const RESOURCES = {
	QUOTE: `quote`,
	BREED: `breed`,
	WIDGET: `widget`
}

export const getEndpoint = (...args: string[]): string => {
	const paths: string[] = [BASE_URL, ...args]

	return paths.join(`/`)
}
